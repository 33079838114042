import { Auth, Amplify } from 'aws-amplify';

export default async function configure() {
  const cognito = {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  };
  let graphql_endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  const STAGE = process.env.REACT_APP_STAGE;
  if (STAGE) {
    const s = await fetch(`https://test.flowour.money/${STAGE}/crm/cloudformation-stack-outputs`);
    const { CognitoUserPoolId, CognitoUserPoolWebClient } = await s.json();
    cognito.userPoolId = CognitoUserPoolId;
    cognito.userPoolWebClientId = CognitoUserPoolWebClient;
    graphql_endpoint = `https://test.flowour.money/${STAGE}/crm/graphql`;
  }

  Amplify.configure({
    Auth: cognito,
    API: {
      graphql_endpoint,
      graphql_headers: async () => {
        const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        return {
          Authorization: jwt,
        };
      },
    },
  });
}
