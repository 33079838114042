import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import configure from '../../amplify';
import { ReviewPage } from '../ReviewPage';
import { SearchPage } from '../SearchPage';

import '../../assets/styles.css';
import { FlexDiv } from '../shared';
import { SideNav } from '../shared/SideNav';
import { MainContentWrapper } from '../shared/styled';
import { SidePanel } from '../shared/SidePanel';
import { TransactionsPage } from '../TransactionsPage';

configure();

const App = () => {
  return (
    <BrowserRouter>
      <FlexDiv
        direction="row"
        align="stretch"
        style={{ backgroundColor: '#f0f2f5', overflow: 'hidden', height: '100vh' }}
        >
        <SideNav />
        <MainContentWrapper>
        <Routes>
          <Route path="/kyc-review" element={<ReviewPage />}>
            <Route path=':id' element={<SidePanel />}></Route>
          </Route>
          <Route path="/users" element={<SearchPage />}>
            <Route path=':id' element={<SidePanel />}></Route>
          </Route>
          <Route path="/transactions" element={<TransactionsPage />}>
            <Route path=':id' element={<SidePanel />}></Route>
          </Route>
        </Routes>
        </MainContentWrapper>
      </FlexDiv>
    </BrowserRouter>
  );
};

export default withAuthenticator(App, {hideSignUp: true});
