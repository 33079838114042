import { API, graphqlOperation } from 'aws-amplify';
import { getAllMonetaryAccountsQuery } from './query';

export interface MonetaryAccount {
  id: string;
  bankAccountId: string;
  createdAt: string;
  updatedAt: string;
  iban?: string;
  displayName: string;
  legalName: string;
  kind: 'UNKNOWN' | 'PERSONAL' | 'JOINT' | 'BUSINESS'; // see fullAccountKind
  accountState: 'LOCKED' | 'ACTIVE' | 'DISCONNECTED' | 'REPLACED' | 'REMOVED';
  balance?: number;
  currency: string;
  onlyWithSCA: boolean;
}

export async function getAllMonetaryAccounts(userId: string): Promise<MonetaryAccount[]> {
  const result = await API.graphql(
    graphqlOperation(getAllMonetaryAccountsQuery, {
      userId,
    })
  );
  return result.data.getUserMonetaryAccounts;
}
