import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/icons/flow-logo.svg';

const FlowLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: white;

  img {
    width: 32px;
    height: 32px;
  }

  div {
    font-size: 18px;
    color: #1890ff;
    margin-left: 10px;
  }
`;

const FlowLogo = () => {
  return (
    <FlowLogoWrapper>
      <img alt="flow-logo" src={logo} />
      <div>Flow CRM</div>
    </FlowLogoWrapper>
  );
};

export default FlowLogo;
