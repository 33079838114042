import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { MonetaryTransaction } from "../../data/getAllTransactions";
import { getAllTransactionsQuery } from "../../data/query";
import { useQuery } from "@apollo/client";
import { TableRow } from "../ReviewPage/types";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from '@atlaskit/button';

const TableCell = styled.div`
  height: 44px;
  display: flex;
  padding-left: 8px;
`;

const head = {
    cells: [
        {
            key: 'transaction',
            content: 'Id',
            isSortable: true
        },
        {
            key: 'occurred',
            content: 'Occurred',
            isSortable: true
        },
        {
            key: 'kind',
            content: 'Kind',
            isSortable: true
        },
        {
            key: 'account_kind',
            content: 'Account kind',
            isSortable: true,
        },
        {
            key: 'from_account_bic',
            content: 'From BIC',
            isSortable: true
        },
        {
            key: 'amount',
            content: 'Amount',
            isSortable: true,
        },
        {
            key: 'balanceAfterMutation',
            content: 'Balance after mutation',
            isSortable: true
        },
    ],
};

interface TransactionTypes {
    getAllTransactions: MonetaryTransaction[];
}

function TransactionsPage() {
    const { data, loading, fetchMore } = useQuery<TransactionTypes>(getAllTransactionsQuery, {
        variables: { limit: 200, offset: 0 },
        notifyOnNetworkStatusChange: true,
    });

    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'EUR'
    });

    const [tableData, setTableData] = useState<TableRow[]>([]);
    useEffect(() => {
        if (data) {
            const rows = data.getAllTransactions.map((transaction) => ({
                key: `${transaction.id}`,
                cells: [
                    {
                        key: 'transactionId' + transaction.id,
                        content: (
                            <TableCell>
                                {transaction.id}
                            </TableCell>
                        ),
                    },
                    {
                        key: 'occurred' + transaction.occurred,
                        content: (
                            <TableCell>
                                {transaction.occurred}
                            </TableCell>
                        ),
                    },
                    {
                        key: 'kind' + transaction.kind,
                        content: (
                            <TableCell>
                                {transaction.kind}
                            </TableCell>
                        ),
                    },
                    {
                        key: 'accountKind' + transaction.party?.accountKind,
                        content: (
                            <TableCell>
                                {transaction.party?.accountKind}
                            </TableCell>
                        ),
                    },
                    {
                        key: 'from_account_bic' + transaction.party?.bic,
                        content: (
                            <TableCell>
                                {transaction.party?.bic}
                            </TableCell>
                        ),
                    },
                    {
                        key: transaction.amount,
                        content: (
                            <TableCell>
                                {formatter.format(transaction.amount)}
                            </TableCell>
                        ),
                    },
                    {
                        key: transaction.balanceAfterMutation,
                        content: (
                            <TableCell>
                                {formatter.format(transaction.balanceAfterMutation)}
                            </TableCell>
                        ),
                    },
                ],
            }));

            setTableData(rows);
        }
    }, [data]);

    return (<>
        <DynamicTable
            head={head}
            rows={tableData}
            rowsPerPage={50}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={loading}
        />
        <Button isDisabled={loading} appearance="primary" onClick={() => { fetchMore({ variables: { offset: data?.getAllTransactions.length || 0 } }) }}>
            Load more
        </Button>
    </>
    );
}

export default TransactionsPage;