import React from 'react';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { RadioGroup } from '@atlaskit/radio';
import { OptionsPropType } from '@atlaskit/radio/types';
import TextArea from '@atlaskit/textarea';
import Button from '@atlaskit/button';

interface IProps {
  modalVisible: boolean;
  modalTitle: string;
  handleCancel: () => void;
  handleAccept: () => void;
  options: OptionsPropType;
  optionValue: string;
  setOptionValue: (option: number) => void;
  note?: string;
  setNoteValue?: (note: string) => void;
}

export const ReviewModal = ({
  modalVisible,
  modalTitle,
  handleAccept,
  handleCancel,
  options,
  optionValue,
  setOptionValue,
  note = '',
  setNoteValue,
}: IProps) => {
  const optionName = options.filter(o => o.value === optionValue)[0].name;
  const optionLabel = options.filter(o => o.value === optionValue)[0].label;
  return (
    <ModalTransition>
      {modalVisible && (
        <Modal onClose={handleCancel}>
          <ModalHeader>
            <ModalTitle>{modalTitle}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <RadioGroup
              value={optionValue}
              options={options}
              onChange={(e) => setOptionValue(Number(e.currentTarget.value))}
              aria-labelledby="radiogroup-label"
            />
            {optionName === 'other' || (note && note.length === 2) ? (
              <TextArea
                resize="auto"
                value={note || optionLabel?.toString()}
                onChange={(e) => setNoteValue && setNoteValue(e.currentTarget.value)}
                />
              ) : null }
          </ModalBody>
          <ModalFooter>
            <Button appearance="primary" onClick={handleAccept}>
              Ok
            </Button>
            <Button appearance="danger" onClick={handleCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
