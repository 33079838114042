import React from 'react';
import { App } from './components/App';
import { Auth } from 'aws-amplify';
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createRoot } from 'react-dom/client';
import '@atlaskit/css-reset';
import './theme/fonts.css';

const STAGE = process.env.REACT_APP_STAGE;
const httpLink = createHttpLink({
  uri: STAGE ? `https://test.flowour.money/${STAGE}/crm/graphql` : process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async () => {
  const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getAllTransactions: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          }
        }
      }
    }
  }),
});

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  window.history.replaceState({}, '', path);
}

const AppRoot = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(AppRoot());

