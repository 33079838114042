import React, { useEffect, useState } from "react"
import { TabContentWrapper } from "../SidePanel.elements"
import { MonetaryAccount, getAllMonetaryAccounts } from "../../../../data/getAllMonetaryAccounts"
import Spinner from '@atlaskit/spinner';
import Card from "../../general/Card";
import { daysAgo } from "../FlowsTab/FlowsTab";
import moment from "moment";

const AccountsTab:React.FC<{userId: string}> = ({userId}) => {
  const [accounts, setAccounts] = useState<MonetaryAccount[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const x = async function () {
      const MA = await getAllMonetaryAccounts(userId);
      setAccounts(MA);
      setLoading(false);
    };
    x();
  }, [])

  if (loading) {
    <TabContentWrapper>
      <Spinner />
    </TabContentWrapper>
  }

  if (!accounts || accounts && accounts.length === 0) {
    <TabContentWrapper>
      No connected accounts
    </TabContentWrapper>
  }

  return (
    <TabContentWrapper>
      {accounts.map(a => {
        return (
          <div style={{marginBottom: 24}} key={a.id}>
            <Card>
              <h3>{a.displayName} <small>({a.accountState})</small></h3>
              <div><strong>{a.iban || a.id}</strong> <small>({a.kind})</small></div>
              <div>
                <strong>Created:</strong> <span title={a.createdAt ?? ''}>{daysAgo(a.createdAt ?? '')}</span>
              </div>
              <div>
                <strong>Last update:</strong> {moment(a.updatedAt).format('HH:mm:ss (YYYY-MM-DD)')}
              </div>
              <div>
                <strong>Legal name:</strong> {a.legalName}
              </div>
            </Card>
          </div>
        )
      })}
    </TabContentWrapper>
  )
}


export default AccountsTab